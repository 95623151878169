import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/headercontact"

const ContactUs = () => (
  <Layout>
    <SEO title="Contact us" />

    <Header />
    <section className="section py-8 px-8 pb-9">
      <div className="container-fluid">
        <div className="py-8">
          <div className="row justify-content-between">
            <div className="col-12 col-md-4 col-xl-3">
              <aside className="mb-9 mb-md-0">
                <h5>
                  <strong>Call us:</strong>
                </h5>
                <p className="text-gray">Working hours from 08:00 to 17:30 Hrs</p>
                {/* <p>
                  <strong>Phone number:</strong>
                  <br />
                  <a className="text-gray" href="tel:60146-389-574">
                    +250 788 520 269
                  </a>
                  <br />
                  <a className="text-gray" href="tel:60146-389-574">
                    +250 788 300265
                  </a>
                </p> */}
                <hr />
                <p>
                  <strong>Email:</strong>
                  <br />
                  <a className="text-gray" href="mailto:customer@example.com">
                    info@studyinrwanda.rw
                  </a>
                  <br />

                  <a className="text-gray" href="mailto:customer@example.com">
                    partnerships@sir.rw
                  </a>
                </p>
                <hr />
                <h5>
                  <strong>Find us:</strong>
                </h5>
                <p className="mb-0 text-gray">
                  P.O Box: 1577 Kigali – Rwanda
                  <br />
                  Nyarutarama MTN Centre 1 st floor
                  <br />
                  KG 9, Ave Kigali
                </p>
                <hr />
                <h5>
                  <strong>Follow us:</strong>
                </h5>
                <p>
                  <a
                    href="https://www.youtube.com/channel/UCyENy45rg7v818eWFuSXTCw"
                    target="_blank"
                    className="m-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#282727"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-youtube"
                    >
                      <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path>
                      <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon>
                    </svg>
                  </a>

                  <a
                    href="https://web.facebook.com/studyin.rwanda.9?ref=bookmarks"
                    target="_blank"
                    className="m-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#282727"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-facebook"
                    >
                      <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                    </svg>
                  </a>

                  <a
                    href="https://twitter.com/RwandaStudyin"
                    target="_blank"
                    className="m-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#282727"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-twitter"
                    >
                      <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                    </svg>
                  </a>

                  <a
                    href="https://www.linkedin.com/in/study-in-rwanda-b83a6217a/"
                    target="_blank"
                    className="m-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#282727"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-linkedin"
                    >
                      <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                      <rect x="2" y="9" width="4" height="12"></rect>
                      <circle cx="4" cy="4" r="2"></circle>
                    </svg>
                  </a>
                </p>
              </aside>
            </div>
            <div className="col-12 col-md-8">
              <form>
                <div className="form-group">
                  <label className="sr-only" htmlFor="contactName">
                    Your Name *
                  </label>
                  <input
                    className="form-control "
                    id="contactName"
                    type="text"
                    placeholder="Your Name *"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="sr-only" htmlFor="contactEmail">
                    Your Email *
                  </label>
                  <input
                    className="form-control "
                    id="contactEmail"
                    type="email"
                    placeholder="Your Email *"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="sr-only" htmlFor="contactTitle">
                    Title *
                  </label>
                  <input
                    className="form-control "
                    id="contactTitle"
                    type="text"
                    placeholder="Title *"
                    required
                  />
                </div>
                <div className="form-group mb-7">
                  <label className="sr-only" htmlFor="contactMessage">
                    Message *
                  </label>
                  <textarea
                    className="form-control "
                    id="contactMessage"
                    rows={5}
                    placeholder="Message *"
                    required
                    defaultValue={""}
                  />
                </div>
                <button className="btn btn-sm btn-dark">Send Message</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ContactUs
